import "../scss/style.scss";
import { sliders } from "./sliders";
import { sticky } from "./sticky";
import { filter } from "./filter";
import { feed } from "./feed";

function init() {
    window.addEventListener("scroll", sticky, {passive: true});
}

sliders();
filter();
feed();
init();

import {tns} from "tiny-slider/src/tiny-slider";
import "tiny-slider/src/tiny-slider.scss";

export function sliders() {
    let slider = document.getElementById('main-slider-gallery');
    let sliderCaption = document.getElementById('main-slider-caption');
    let sliderContent = document.getElementById('main-slider-content');

    if (slider) {
        let image = slider.querySelectorAll('.image');

        if (image.length > 1) {
            tns({
                container: '#main-slider-gallery',
                mode: 'gallery',
                loop: true,
                controls: false,
                nav: true,
                navContainer: '#main-slider-nav',
                arrowKeys: false,
                items: 1,
                speed: 2000,
                autoplay: true,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
            });
        }
    }
    if (sliderCaption) {
        let caption = sliderCaption.querySelectorAll('.caption');

        if (caption.length > 1) {
            tns({
                container: '#main-slider-caption',
                mode: 'gallery',
                loop: true,
                controls: false,
                nav: true,
                navContainer: '#main-slider-nav',
                arrowKeys: false,
                items: 1,
                speed: 2000,
                autoplay: true,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
            });
        }
    }
    if (sliderContent) {
        let content = sliderContent.querySelectorAll('.content');

        if (content.length > 1) {
            tns({
                container: '#main-slider-content',
                mode: 'gallery',
                loop: true,
                controls: false,
                nav: true,
                navContainer: '#main-slider-nav',
                arrowKeys: false,
                items: 1,
                speed: 2000,
                autoplay: true,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
            });
        }
    }
}

export async function feed() {
    // let response = await fetch('https://api.ideemedia.cloud/instagram/17841404464687927/media');
    //
    // if (response.ok) {
    //     let json = await response.json();
    //     json = json.data;
    //
    //     let feed = document.getElementById('instafeed');
    //
    //     if(feed)
    //     {
    //         for (let i = 0; i < json.length; ++i)
    //         {
    //             let type = json[i].media_type;
    //             let url = json[i].media_url;
    //
    //             let item = '';
    //             if(type === "VIDEO") {
    //                 item = '<video src="'+url+'" class="video"></video>';
    //             } else
    //             {
    //                 item = '<img src="'+url+'" class="image" />';
    //             }
    //
    //             feed.innerHTML
    //                 += '<a href="'+url+'" class="photo-link" data-width="1080" data-height="1080" data-large-url="'+url+'">'
    //                 + item
    //                 + '</a>'
    //         }
    //     }
    // }
}

import anime from "animejs";

export function filter()
{
    Array.from(document.querySelectorAll("[data-project-filter]"))
        .forEach(initializeFilter);
}

function initializeFilter(root)
{
    let checkbox = Array.from(root.querySelectorAll('input.filter-item-input'));
    checkbox.forEach(cb => cb.addEventListener("change", applyFilters));

    let projects = Array.from(root.querySelectorAll(".project-col"));

    function applyFilters()
    {
        let selectedFilters = [];

        checkbox.forEach(cb => cb.checked && selectedFilters.push(cb.name));

        let results = selectedFilters.length === 0 ? projects : projects.filter(project =>
        {
            let filters = project.dataset.label.split(";");
            let isMatch = false;

            selectedFilters.forEach(sf => isMatch || (isMatch = filters.includes(sf)));

            return isMatch;
        });

        anime.remove(projects);

        anime({
            targets: projects,
            opacity: 0,
            duration: 210,
            easing: "cubicBezier(.55, 0, .1, 1)",
            complete: () =>
            {
                projects.forEach(project => project.classList.add("d-none"));
                results.forEach(result => result.classList.remove("d-none"));

                anime({
                    targets: results,
                    opacity: 1,
                    translateY: [96, 0],
                    easing: "spring(1, 80, 10, 0)",
                    delay: anime.stagger(50, {start: 100})
                })
            }
        });

        localStorage.setItem("filterChecked", JSON.stringify(checkbox.filter(cb => cb.checked).map(cb => cb.value)));
    }

    // Storage Filter
    document.addEventListener("DOMContentLoaded", function() {
        let filterChecked = JSON.parse(localStorage.getItem('filterChecked') || '[]');

        filterChecked.forEach(function(value) {
            checkbox.filter(cb => cb.value === value).forEach(function(cb) {
                cb.checked = true;
            });
        });

        applyFilters();
    });
}
